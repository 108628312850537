import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulContenuDePage(slug: { eq: "about" }) {
        slug
        text {
          raw
        }
        text2 {
          raw
        }
        text3 {
          raw
        }
        image {
          title
          gatsbyImageData
        }
      }
    }
  `)
  const text2 = (
    <section className="section full-width bg-primary">
      <div className="grid-container">
        {renderRichText(data.contentfulContenuDePage.text2)}
      </div>
    </section>
  )

  const text3 = (
    <section className="section">
      <div>{renderRichText(data.contentfulContenuDePage.text3)}</div>
    </section>
  )

  const pageImage = getImage(data.contentfulContenuDePage.image)

  return (
    <Layout title="A propos de Jérôme Dicharry, développeur web Gatsby JS à Strasbourg">
      <Head
        title="A propos de Jérôme Dicharry, développeur web Gatsby JS | Next.js | React à Strasbourg"
        description="A propos de Jérôme Dicharry, développeur web Gatsby JS | Next.js | React à Strasbourg Alsace"
      />
      <h2 className="mb-0 mt-section">
        Agences web, agences de communication, sous-traitez le développement de
        vos sites web à un développeur Gatsby JS
      </h2>
      <section className="section section--small">
        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            <GatsbyImage
              className="avatar"
              image={pageImage}
              alt={data.contentfulContenuDePage.image.title}
              placeholder="blurred"
              layout="fixed"
              width={400}
            />
          </div>
          <div className="cell large-8">
            {renderRichText(data.contentfulContenuDePage.text)}
          </div>
        </div>
      </section>
      {text2}
      {text3}
      <section className="section full-width bg-primary">
        <div className="grid-container">
          <h2>Une question, un projet ? </h2>
          <p>
            <Link to="/contact" className="button primary-dark">
              Contactez-moi.
            </Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
